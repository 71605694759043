import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from 'src/app/helpers/global.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ResendEmailComponent } from '../resend-email/resend-email.component';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	hide = false;
	form: FormGroup;
	submitted = false;
	errors: any = [];

	resendEmail: MatDialogRef<ResendEmailComponent>;

	isCollapsed = true;

	constructor(
		private fb: FormBuilder,
		private authService: AuthService,
		public gs: GlobalService,
		private dialog: MatDialog,
	) { }

	get f() { return this.form.controls; }

	ngOnInit() {
		this.creteLoginForm();

		// this.form.get('email').valueChanges.pipe(debounceTime(800)).subscribe(data => {

		// });
	}

	onLogin(form) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			return false;
		}
		this.authService.login(form.value).subscribe((auth: any) => {
				this.submitted = false;
				if (auth) {
					this.gs.alert('Login Successful');
					window.location.href = '/user/explore';
				}
				//this.router.navigate(['/timeline']);
			}, (error: any) => {
				this.submitted = false;
				console.log('error', error)
				this.errors = error;
				if (error.status === 422) {
					const dispaly = error.error;
					if (dispaly.data.user_id) {
						this.resendEmail = this.dialog.open(ResendEmailComponent, {
							width: '390px',
							panelClass: 'custom-dialog',
							data: dispaly.data.user_id ?? 0
						});
					} else {
						this.gs.handleErrors(error);
					}
				} else {
					this.gs.handleErrors(error);
				}
			})
	}

	creteLoginForm() {
		this.form = this.fb.group({
			username: new FormControl('', [Validators.required]),
			password: new FormControl('', Validators.required)
		})
	}

}
