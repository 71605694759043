import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../auth/services/auth.service';
import { Title } from '@angular/platform-browser';
import { postVideos } from '../models/Post';
import { Store } from '@ngrx/store';
import { User } from '../models/User';
import { ApiResponse } from '../models/ApiResponse';

@Injectable()
export class GlobalService {
	webStream = environment.webStream;
	public errors = [];
	//public mainTitle = "SocialCross";

	public mainTitle = "ChatDit";
	public setUser = null;
	public user: User = null;
	public uploadPath = environment.uploadPath;
	data = localStorage.getItem('user');
	public identity = (this.data) ? JSON.parse(this.data) : null;
	store = this.stores;
	formBuilder = this.fb;

	constructor(
		private snackBar: MatSnackBar,
		private router: Router,
		private http: HttpClient,
		private authService: AuthService,
		private titleService: Title,
		private stores: Store<{}>,
		private fb: FormBuilder

	) {
		const user = localStorage.getItem('user');
		const obj = JSON.parse(user);
		//console.log(obj);
		if (obj !== null) {
			this.setUser = obj.userProfile;
			this.user = obj;
		}
		
		this.user = (obj !== null) ? obj : null;
	}

	public getUser() {
		return this.user
	}

	public clearErrorMessages() {
		this.errors = [];
	}

	public checkMimetypeVideo(mimetype) {
		const check = (mimetype === 'video/mp4') || (mimetype === 'video/mp3') || (mimetype === 'video/mov') || (mimetype === 'video/wav');
		return check;
	}

	public checkMimetypeImage(mimetype) {
		const check = (mimetype === 'image/jpeg') || (mimetype === 'image/jpg') || (mimetype === 'image/png') || (mimetype === 'image/gif');
		return check;
	}

	public siteTitle() {
		this.mainTitle;
	}

	public capitalize(s) {
		let space = s.split('.').join(' ');
		return space && space.charAt().toUpperCase() + space.slice(1);
	}

	public handleHttpError(error: HttpErrorResponse) {
		return throwError(error || 'Server Error');
	}

	public handleErrors(error: any) {
		const httpErrorCode = error.status;
		this.clearErrorMessages();
		switch (httpErrorCode) {
			case 401:
				const msg = error.error.message;
				this.errors.push(msg);
				this.authService.logout();
				/*for (const msg1 in msg) {
				  this.errors.push(msg[msg1]);
				}*/
				this.alert(msg, 'bg-warning');
				break;
			case 403:
				this.router.navigateByUrl('/');
				break;
			case 422:
				const messages = error.error.data;
				const messages2 = error.error.data?.errors;
				for (const message in messages) {
					if (messages[message]) {
						this.errors.push(messages[message].join(''));
					}
				}
				for (const message in messages2) {
					if (messages[message]) {
						this.errors.push(messages[message].join(''));
					}
				}
				(messages !== null) && this.alert(this.errors[0], 'bg-warning');
				(messages === null) && this.alert(error.error.message, 'bg-danger');
				break;
			default:
				this.alert('An error occurred while processing your request.', 'bg-danger');
		}
	}

	checkIframe(item) {
		/*var temporalDivElement = document.createElement("div");
		temporalDivElement.innerHTML = item;
		const elements = temporalDivElement.getElementsByTagName('iframe');
		return (elements[0] === undefined) ? true : false;*/
	}

	timelineTitleRole(module, owner, user) {
		switch (module) {
			case 'Page':
                if(owner == user) { return true; }
				break;
			case 'Group':
                if(owner == user) { return true; }
				break;
			case 'UserEvent':
                if(owner == user) { return true; }
				break;
		}
		//return returnData;
	}

	checkVideoUrl(text) {
		const urls = /(\b(https?|http|ftp|ftps|Https|rtsp|Rtsp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim; // Find/Replace URL's in text	
		const youtube = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
		const vimeo = /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/;
		const facebook = /^http(?:s?):\/\/(?:www\.|web\.|m\.)?facebook\.com\/([A-z0-9\.]+)\/videos(?:\/[0-9A-z].+)?\/(\d+)(?:.+)?$/;
		const facebook2 = /^https:\/\/www\.facebook\.com\/(photo(\.php|s)|permalink\.php|media|questions|notes|[^\/]+\/(activity|posts))[\/?].*$/;
		const priviewLink = (text && text.match(urls) !== null) ? text && text.match(urls)[0] : [];
		if(priviewLink.length > 0) {
			if(priviewLink.match(youtube)) {
				return `<iframe title="${priviewLink.match(youtube)[1]}" class="w-100" width="560" height="300" src="https://www.youtube.com/embed/${priviewLink.match(youtube)[1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
			} else if(priviewLink.match(vimeo)) {
				return `<iframe title="${priviewLink.match(vimeo)[4]}" class="w-100" src="https://player.vimeo.com/video/${priviewLink.match(vimeo)[4]}?color=bd2f00&title=0&byline=0&portrait=0" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
			} else if(priviewLink.match(facebook)) {
				return `<iframe title="${priviewLink.match(facebook)[1]}" class="w-100" src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F${priviewLink.match(facebook)[1]}%2Fvideos%2F${priviewLink.match(facebook)[2]}%2F&show_text=0&width=560" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
			} else if(priviewLink.match(facebook2)) {
				return `<iframe title="${priviewLink.match(facebook2)[1]}" class="w-100" src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F${priviewLink.match(facebook2)[1]}%2Fvideos%2F${priviewLink.match(facebook2)[2]}%2F&show_text=0&width=560" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
			}
		} else {
			return false;
		}
	}

	checkLiveStream(postVideos: postVideos) {
		//let url = (postVideos.is_live === 1) ? postVideos.url : `streams/${postVideos.url}`;
		//let live_url = `${this.webStream}?name=${url}`
		return `<iframe id="postVideos_live" src="${postVideos.url}" class="w-100" width="640" height="300" frameborder="0" fullscreen allowfullscreen></iframe>`;
	}

	checkBrowser() {
		// Get the user-agent string 
		let returnValue = false;
		let userAgentString = navigator.userAgent; 
		// Detect Chrome 
		let chromeAgent = userAgentString.indexOf("Chrome"); 
		if(chromeAgent > 40) {
			console.log('If condition')
		} else {
			console.log('esle COndition')
		}
		console.log('chromeAgent', chromeAgent, userAgentString);
		// Detect Internet Explorer 
		let IExplorerAgent =  
		userAgentString.indexOf("MSIE") > -1 ||  
		userAgentString.indexOf("rv:") > -1; 
  
		// Detect Firefox 
		let firefoxAgent =  
			userAgentString.indexOf("Firefox") > -1; 
	
		// Detect Safari 
		let safariAgent =  
			userAgentString.indexOf("Safari") > -1; 
			
		// Discard Safari since it also matches Chrome 
		if ((chromeAgent) && (safariAgent))  
			safariAgent = false; 
	
		// Detect Opera 
		let operaAgent =  
			userAgentString.indexOf("OP") > -1; 
			
		// Discard Chrome since it also matches Opera      
		//if ((chromeAgent) && (operaAgent)) { chromeAgent = false; }
	}

	base64toFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
	}

	shortNumber(number) {
		const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
		// what tier? (determines SI symbol)
		const tier = Math.log10(number) / 3 | 0;
		// if zero, we don't need a suffix
		if(tier == 0) return number;
		// get suffix and determine scale
		const suffix = SI_SYMBOL[tier];
		const scale = Math.pow(10, tier * 3);
		// scale the number
		const scaled = number / scale;
		// format number and add suffix
		return scaled.toFixed(1) + suffix;
	}

	setTitle(title = '') {
		this.titleService.setTitle(this.mainTitle + ' | ' + title);
	}
	
	generateFake(count: number): Array<number> {
		const indexes = [];
		for (let i = 0; i < count; i++) {
			indexes.push(i);
		}
		return indexes;
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
		  const control = formGroup.get(field);
		  if (control instanceof FormControl) {
			control.markAsTouched({ onlySelf: true });
		  } else if (control instanceof FormGroup) {
			this.validateAllFormFields(control);
		  }
		});
	}

	isValid(fieldName, form?): boolean {
		return form && form[fieldName] && form[fieldName].invalid && (form[fieldName].dirty || form[fieldName].touched);
	}

	addCls(field: string) {
		return {
			'is-invalid': this.isValid(field),
			'has-feedback': this.isValid(field)
		};
	}

	files(event, form) {
		form.patchValue({ files: event })
	}

	ObjectToArray(objs) {
		const items = [];
		objs && Object.keys(objs).map(itemKey => {
			const item = objs[itemKey];
			items.push(item);
		});
		return items;
	};

	alert(msg = 'Empty', className = 'bg-success') {
		this.snackBar.open(msg, null, {duration: 5000, verticalPosition: 'bottom', horizontalPosition: 'end', panelClass: className});
	}

	updateProfile() {
		/*this.userService.profile().subscribe((data: any) => {
			this.store.dispatch(new AuthActions.loginSuccess({ user: data }));
		});*/
	}

	notificationLink(item) {
		let link = '';
		let mo = item.item_type;
		let mo2 = mo.split('\\');
		let module = mo2.pop();
		//console.log(item);
		switch (module) {
			case 'UserFriendship':
                if(item.item_type === 'friend-pending') link = `/user/friend-request`;
                link = `/user/friend-request`;
                break;
			case 'UserHelper':
				link = `/user/friend-request`;
				break;
			case 'Post':
				link = `/timeline/post-view/${item.item_id}`;
				break;
			case 'Group':
				link = `/groups/view/${item.item_id}/timeline`;
				break;
			case 'Page':
				link = `/pages/view/${item.item_id}/timeline`;
				break;
			case 'Event':
				link = `/events/view/${item.item_id}/timeline`;
				break;
			case 'UserEvent':
				link = `/events/view/${item.item_id}/timeline`;
				break;
			case 'UserComment':
				if(item.action === 'add-reaction' || item.action === 'reply') {
					//console.log('itemitem', item);
					link = `/timeline/post-view/${item.textJson?.baseModule?.item_id}`;
				} else {
					link = `/timeline/post-view/${item.item_id}`;
				}
				break;
			case 'UserMessage':
				link = `/messages/sender/${item.item_id}`;
				break;
            case 'UserFamilyMember':
                if(item.item_type === 'create-family-members') {
                    link = `/timeline/timeline-review`;
                }
                break;
			default:
                link = `/user/timeline/${this.user.username}`;
		}
		return link;
	}

	apiResponce(item: ApiResponse) {
		let code = item.code;
		let data = item.data;
		let message = item.message;
		let status = item.status;
		let items = data ? data.items : [];
		let pagination = data ? data.pagination : null;
		let model = data?.model ? data?.model : null;
		return { code: code, message: message, status: status, data: data, model: model, items: items, pagination: pagination }
	}

}