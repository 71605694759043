<div class="blog-list w-100 float-left py-4">
	<div class="container">
		<div class="row">
			<div class="col-md-7 col-12 mx-auto">
				<div class="card page-card">
					<div class="card-header d-flex flex-wrap align-items-center">
						<h3 class="card-title col pl-0">One Time Password (OTP) Verification</h3>
					</div>
					<div class="card-body">
						<form novalidate [formGroup]="forgot" (ngSubmit)="onForgot(forgot)">
							<div *ngIf="gs.errors.length" class="alert alert-danger" role="alert">
								<h4 class="alert-heading">Error Summary:</h4>
								<p *ngFor="let error of gs.errors;" class="mb-0">{{ error }}</p>
							</div>
							<div class="form-group">
							<mat-form-field appearance="outline">
							  	<mat-label>OTP</mat-label>
							  	<input matInput placeholder="OTP" formControlName="otp" required>
								<mat-hint><img src="/assets/img/otp.png" alt="" class="img-fluid">Please enter the OTP code sent to your registered email address & mobile number.</mat-hint>
							  	<mat-error *ngIf="f.otp.errors && f.otp.touched">
									<div *ngIf="f.otp.errors.required">OTP is required</div>
							  	</mat-error>
							</mat-form-field>
							</div>
							<div class="form-group">
							  		<button type="submit" class="btn btn-lg btn-block btn-primary" [ladda]="submitted" [disabled]="!forgot.valid">Verify</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>