import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { MustMatch } from 'src/app/helpers/must-match.validator';
import { CommonService } from 'src/app/service/common.service';
import { GlobalService } from 'src/app/helpers/global.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
	title = 'Register';
	hide = false;
	form: FormGroup;
	submitted = false;
	isLoading = false;
	filteredCountry = [];
	errors = [];
	phone_code: any = '+1';

	constructor(
		private fb: FormBuilder,
		private authService: AuthService,
		private commonService: CommonService,
		public gs: GlobalService,
		private titleService: Title,
		private router: Router
	) { }

	get f() { return this.form.controls; }

	ngOnInit() {
		this.titleService.setTitle(this.gs.mainTitle + ' | ' + this.title);
		this.form = this.fb.group({
			email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
			username: new FormControl('', [Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(30), Validators.pattern(/^[a-zA-Z0-9\.\-\_]+$/)])]),
			password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(24)]),
			confirm_password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(24)]),
			first_name: new FormControl('', [Validators.required]),
			last_name: new FormControl('', [Validators.required]),
			phone_number: new FormControl('', [Validators.compose([Validators.required, Validators.pattern(/^((\\+91-?)|0)?[0-9]{10}$/)])]),
			country_code: new FormControl('', [Validators.required]),
			gender: new FormControl('', [Validators.required]),
			terms: new FormControl('', [Validators.required]),
		}, { validators: MustMatch('password', 'confirm_password') }); // Form validation


		this.commonService.country().subscribe((data: any) => {
			//console.log('data.data', data.data);
			this.filteredCountry = data.data;
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
		
		this.form.get('country_code').valueChanges.subscribe(value => {
			let findvalue = this.filteredCountry.filter(val => val.iso2 === value);
			this.phone_code = findvalue[0].phone_code;
			//console.log('value', value, findvalue[0].phone_code);
		});

	}

	onRegister(form: FormGroup) {
		this.errors = null;
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			this.gs.validateAllFormFields(this.form);
			return;
		}
		this.gs.clearErrorMessages();
		let params = { ...form.value };
		params['phone_number'] = `${this.phone_code}${form.value.phone_number}`;
		this.authService.register(params).subscribe((data: any) => {
			//console.log('data', data);
			this.submitted = false;
			this.gs.alert('You have successfully registered please check your email or phone for the verification code!');
			if(data.code) {
				this.form.reset();
				this.router.navigate(['/otp-verification']);
			} else {
				let errors: Response = data;
				this.gs.handleErrors(errors);
				console.log('errorserrors', errors)
				this.gs.alert('Action not working.', 'bg-danger');
			}
		}, (error: Response) => {
			console.log('error', error);
			this.gs.handleErrors(error);
			this.submitted = false;
		});
	}
}
